import { environment } from 'src/environments/environment';

export type Target = '_blank' | '_self' | '_parent' | '_top' | 'framename';

export const download = (url: string, target?: Target, name?: string) => {
    if (!url) {
        throw new Error('Invalid url');
    }

    if (url.indexOf('http') === -1) {
      url = `${environment.urlAPI}${url}`;
    }

    const element = document.createElement('a');
    element.setAttribute('href', url);

    if (name) {
        element.setAttribute('download', name);
    }
    if (target) {
        element.setAttribute('target', target);
    }

    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};

export async function loadFileFromUrl(url: string): Promise<Blob> {
  try {
      // Faz a requisição para a URL
      const response = await fetch(url);

      // Verifica se a requisição foi bem-sucedida
      if (!response.ok) {
          throw new Error(`Erro ao carregar o arquivo: ${response.statusText}`);
      }

      // Converte a resposta em um Blob
      const blob = await response.blob();

      return blob;
  } catch (error) {
      console.error('Erro ao carregar o arquivo:', error);
      throw error;
  }
}
